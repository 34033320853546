<template>
  <div class="page_box">
    <div class="active_header">
      <img :src="bannder" :alt="$t('siffa')" />
    </div>
    <div class="active_name">
      <span>{{ title }}</span>
    </div>
    <div class="box" v-loading="loading">
      <template v-if="activitys_data.length != 0">
        <activitysItem
          v-for="(item, i) in activitys_data"
          :key="i"
          :class="{ is_left: (i + 1) % 2 == 1 }"
          :default_img="default_img"
          :img_important="img_important"
          :item="item"
        ></activitysItem>
      </template>
      <!-- <div class="loading_all_center"> -->
      <!-- <isAllDataTip
          v-if="total != 0 && total == activitys_data.length"
        ></isAllDataTip>
        <isLoadingTip v-if="loading"></isLoadingTip> -->
      <!-- </div> -->
      <noDataImg v-if="activitys_data.length == 0 && !loading" />
    </div>
    <div class="clearfix content_area" v-show="activitys_data.length">
      <el-pagination
        class="fr"
        style="margin: 10px 0 30px"
        :current-page="currentPage"
        @current-change="handleChange"
        :total="total"
        background
        :page-size="pageSize"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import noDataImg from "@/baseComponents/noDataImg";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import activitysItem from "~hph/components/index/activitys/activitysItem";
export default {
  components: {
    noDataImg,
    isLoadingTip,
    isAllDataTip,
    activitysItem,
  },
  data() {
    return {
      is_all: false,
      pageSize: 10,
      bannder:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/Banner_tree.png",
      title: "",
      default_img: "",
      default_xhdt:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/AssociationActivities.png",
      default_xhdtEn:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/AssociationActivitiesEn.png",
      default_hyxx:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/InformationIndustry.png",
      default_hyxxEn:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/InformationIndustrEn.png",
      default_zcfg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/Policies_regulations.png",
      default_zcfgEn:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/Policies_regulationsEn.png",
      default_hdtz:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/AssociationNews.png",
      default_hdtzEn:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/activeEn.png",
      img_important:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/important.png",
      loading: false,
      total: 0,
      currentPage: 1,
      content_type: "",
      activitys_data: [],
    };
  },
  created() {
    switch (this.$route.query.content_type * 1) {
      case 3:
        this.title = '行业信息';
        this.default_img =
          this.LOCALE == "en" ? this.default_hyxxEn : this.default_hyxx;
      break;

      case 68:
        this.title = '俱乐部动态';
        this.default_img =
          this.LOCALE == "en" ? this.default_hdtzEn : this.default_hdtz;
      break;
      case 69:
        this.title = '会员动态';
        this.default_img =
          this.LOCALE == "en" ? this.default_hdtzEn : this.default_hdtz;
      break;
      default:
        this.$router.push({
          name: "index",
        });
        break;
    }
    document.title = this.title;
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleChange(index) {
      this.currentPage = index;
      this.getList();
    },
    async getList() {
      // 防止多次出发 请求事件
      this.loading = true;
      let params = {};
      params.content_type = this.$route.query.content_type;
      params.start = this.currentPage - 1;
      params.limit = this.pageSize;
      params.source = 33;
      params.language = this.LOCALE === "en" ? 1 : 2;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.loading = false;
        this.total = res.total;
        this.activitys_data = res.data;
        document.body.scrollTop = document.documentElement.scrollTop = 380;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page_box {
  background: #fafafa;
}
.active_header {
  position: relative;
  padding-top: 14.6%;
  overflow: hidden;
  img {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.active_name {
  padding: 24px;
  text-align: center;
  span {
    width: 84px;
    height: 29px;
    font-size: 21px;
    font-weight: 800;
    color: #1f292e;
    line-height: 29px;
  }
}
.box {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
  position: relative;

  .is_left {
    margin-right: 24px;
  }
  .loading_all_center {
    padding: 15px;
    width: 100%;
    text-align: center;
  }
}
</style>